<template>
  <div>
    <v-row>
      <v-col cols="6">
        <h1>Original Sites</h1>
        <select
          v-model="selectedSites"
          @change="updateFinalSites"
          multiple
          size="5"
        >
          <option v-for="site in originalSites" :key="site.id" :value="site">
            {{ site.domain }}
          </option>
        </select>
      </v-col>
      <v-col cols="6">
        <h1>Selected Sites</h1>
        <ul :style="{ cursor: isDragging ? 'grabbing' : 'default' }">
          <li
            v-for="(site, i) in selectedSites"
            :key="site.id"
            draggable="true"
            @dragstart="startDrag(site.id, $event)"
            @dragend="endDrag"
            @dragover="onDragOver"
            :data-index="i"
            class="selected-site-li"
          >
            <div class="site-item">
              <span class="domain">{{ site.domain }}</span>
              <button @click="removeSite(site.id)" class="remove-btn">×</button>
            </div>
          </li>
        </ul>
      </v-col>
    </v-row>

    <p class="mt-8">Final Site List (for reference):</p>
    <pre
      >{{ formattedFinalSites }}
    </pre>
  </div>
</template>

<script>
export default {
  data() {
    return {
      originalSites: [
        { id: 111, domain: "domain1", priority: null },
        { id: 112, domain: "domain2", priority: null },
        { id: 113, domain: "domain3", priority: null },
        { id: 114, domain: "domain4", priority: null },
        // Add more sites as needed
      ],
      selectedSites: [],
      formattedFinalSites: "",
      isDragging: false,
      dragStartIndex: null,
      dragToIndex: null,
    };
  },
  methods: {
    hasParentWithClassUpToN(element, className, n) {
      if (n <= 0 || !element || element === document.body) {
        return false; // Reached limit, body, or null
      }
      if (
        element.parentElement &&
        element.parentElement.classList.contains(className)
      ) {
        return element.parentElement; // Found within n levels
      }
      return this.hasParentWithClassUpToN(
        element.parentElement,
        className,
        n - 1
      ); // Recursive call with n-1 level remaining
    },
    removeSite(id) {
      const index = this.selectedSites.findIndex((site) => site.id === id);
      if (index !== -1) {
        this.selectedSites.splice(index, 1);
        this.updateFinalSites();
      }
    },
    updateFinalSites() {
      const finalSites = this.selectedSites.map((site, index) => ({
        ...site,
        priority: index + 1,
      }));
      this.formattedFinalSites = JSON.stringify(finalSites, null, 2);
    },
    startDrag(id, event) {
      this.isDragging = true;
      this.dragStartIndex = this.selectedSites.findIndex(
        (site) => site.id === id
      );
      event.dataTransfer.setData("text/plain", id);
    },
    endDrag() {
      this.isDragging = false;
      if (this.dragStartIndex != this.dragToIndex) {
        const items = [...this.selectedSites];
        const [removed] = items.splice(this.dragStartIndex, 1);
        items.splice(this.dragToIndex, 0, removed);
        this.selectedSites = items;
        this.updateFinalSites();
      }

      this.dragStartIndex = null;
      this.dragToIndex = null;
      const liEls = document.querySelectorAll(".selected-site-li");
      for (let i = 0; i < liEls.length; i++) {
        liEls[i].classList.remove("selected-site-li-dragged-hover");
      }
    },
    onDragOver(event) {
      event.preventDefault();
      let liEl = this.hasParentWithClassUpToN(
        event.target,
        "selected-site-li",
        3
      );
      if (liEl) {
        this.dragToIndex = liEl.getAttribute("data-index");
        const liEls = liEl.parentElement.querySelectorAll(".selected-site-li");
        console.log("liEls", liEls);
        for (let i = 0; i < liEls.length; i++) {
          const el = liEls[i];
          if (i == this.dragToIndex) {
            el.classList.add("selected-site-li-dragged-hover");
          } else {
            el.classList.remove("selected-site-li-dragged-hover");
          }
        }
      } else {
        this.dragToIndex = null;
      }
    },
  },
  mounted() {
    document.addEventListener("mouseup", this.endDrag);
  },
  beforeDestroy() {
    document.removeEventListener("mouseup", this.endDrag);
  },
};
</script>

<style scoped>
.site-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  position: relative;
}
.selected-site-li:hover {
  border: 1px dashed gray;
}
.selected-site-li-dragged-hover {
  border: 3px outset gray;
}
.domain {
  flex: 1;
}

.remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: none;
  cursor: pointer;
  color: #999;
  font-size: 1.2em;
}

.remove-btn:hover {
  color: #ccc;
}
</style>
